


































































































import { Component, Vue } from 'vue-property-decorator'
import VsFormGroup from '@/components/VsFormGroup/Index.vue'
import VsFormSection from '@/components/VsFormSection/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import { getLayoutSettings } from '@/api/consoleApi/user'
import { getSettings, updateSetting } from '@/api/consoleApi/settings'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { get, set } from 'lodash'
import { UserModule } from '@/store/modules/user'
import { AppModule } from '@/store/modules/app'

@Component({
    name: 'GeneralSettings',
    components: {
        VsFormGroup,
        VsFormSection,
        VsLoader,
    },
})
export default class extends Vue {
    private loading = false
    private pageLoading = false
    private logoImage = ''
    private color = ''

    get logo () {
        return get(UserModule, 'settings.theme.logoConsole', '')
    }

    set logo (logo: string) {
        set(UserModule, 'settings.theme.logoConsole', logo)
    }

    get revisionNumber () {
        return AppModule.consoleConf?.version || ''
    }

    get user () {
        return UserModule.user
    }

    get isManager () {
        return this.user.configuration.rules.manager
    }

    beforeMount () {
        this.getSettings()
    }

    private async getSettings () {
        try {
            this.pageLoading = true
            const resp = await getSettings()
            this.logoImage = resp.data.data.logoConsole
            this.color = resp.data.data.colorConsole
        } catch (e) {
            console.log(e)
        }
        this.pageLoading = false
    }

    private async openMediaGallery (currentUrl: string) {
        try {
            const url = await window.openVsMediaLibrary({
                url: currentUrl,
                mode: 'selection',
                allowedTypes: 'image/jpeg,image/gif,image/png,image/x-icon,image/webp,image/ico,image/icon,image/vnd.microsoft.icon',
            })

            this.logoImage = url || ''
        } catch (e) {
            console.log(e)
        }
    }

    private async saveCustomization () {
        try {
            this.loading = true
            await updateSetting({
                logoConsole: this.logoImage,
                colorConsole: this.color,
            })

            const res = await getLayoutSettings()
            UserModule.SET_SETTINGS(res.data.data)

            this.$root.$vsToast({
                heading: this.$t('settings.general.personalizationSuccess'),
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
        } catch (e) {
            this.$root.$vsToast({
                heading: this.$t('settings.general.personalizationError'),
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            console.log(e)
        }
        this.loading = false
    }
}
